import * as React from 'react';
import { render } from 'react-dom';
import { enableSentry } from '@websky/core/src/utils';
import { withConfig } from '@websky/core/src/config-hoc';
import merge from 'lodash.merge';
import { config as defaultConfig } from '../../config';
enableSentry();
import SearchForm from './SearchForm';
export var init = function (mount, config) {
    var Component = withConfig(SearchForm, merge({}, defaultConfig, config));
    render(React.createElement(Component, null), mount);
};
